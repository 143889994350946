/*eslint-disable*/
import React, { useState } from "react";
import Router from 'next/router';
import Link from 'next/link';

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import Button from "components/CustomButtons/Button.js";
import logout from 'utils/auth/logout'
import styles from "assets/jss/nextjs-material-kit/components/headerLinksStyle.js";
// import useUser from "utils/useUser";
import Hidden from "@material-ui/core/Hidden";
import Collapse from '@material-ui/core/Collapse';

import dynamic from 'next/dynamic'

import fetchJson from "utils/fetchJson";
import useUser from 'utils/useUser'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import BookOutlinedIcon from '@material-ui/icons/BookOutlined';

const DnsIcon = dynamic(() => import('@material-ui/icons/Dns'));
const ExitToAppIcon = dynamic(() => import('@material-ui/icons/ExitToApp'))
const AccountCircleOutlinedIcon = dynamic(() => import('@material-ui/icons/AccountCircleOutlined'))
const LiveTvIcon = dynamic(() => import('@material-ui/icons/LiveTv'))
const EventNoteIcon = dynamic(() => import('@material-ui/icons/EventNote'))
const MicIcon = dynamic(() => import('@material-ui/icons/Mic'))
const InfoIcon = dynamic(() => import('@material-ui/icons/Info'))
const FiberDvrIcon = dynamic(() => import('@material-ui/icons/FiberDvr'))
const ArrowForwardIcon = dynamic(() => import('@material-ui/icons/ArrowForward'));
const HeaderLinksMobile = dynamic(() => import('./HeaderLinksMobile'))
const HeaderLinksMobileAdmin = dynamic(() => import('./HeaderLinksMobileAdmin'))

const useStyles = makeStyles(styles);

const HeaderLinks = props => {
  // const { mutateUser } = useUser();
  const classes = useStyles();

  const [ user, setUser ] = useState(props.user)
  const [ loggedOut, setLoggedOut ] = useState(false)
  const { userr, mutateUser } = useUser()

  const [openHidden, setOpenHidden] = useState(false)

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  // if(typeof window === "undefined" ) return null

  return (
    <List className={classes.list}>

      {(!user?.isLoggedIn) || (loggedOut == true) ? (
        <>
          {props?.domainInfo?.blog && (
            <ListItem className={classes.listItem}>
              <Link href="/blog">
                <Button color="transparent" className={classes.navLink}> 
                    <BookOutlinedIcon className={classes.icons} /> Blog
                </Button>
              </Link>
            </ListItem>
          )}

          {props?.domainInfo?.shop && (
            <ListItem className={classes.listItem}>
              <Link href={props?.domainInfo?.shopredir ? props?.domainInfo?.shopredir : "/shop"}>
                <Button color="transparent" className={classes.navLink}> 
                    <StoreOutlinedIcon className={classes.icons} /> Shop
                </Button>
              </Link>
            </ListItem>
          )}

          <ListItem className={classes.listItem}>
            <Link href="/login">
              <Button color="transparent" className={classes.navLink}> 
                  <AccountCircleOutlinedIcon className={classes.icons} /> Register / Login
              </Button>
            </Link>
          </ListItem>
        </>
      ) : (
        <>
          {props?.domainInfo?.domainName == 'www' ? (
            <>
                <ListItem className={classes.listItem} style={{fontSize: "x-large"}}>
                <Link href="/my/reserve">
                  <Button color="transparent" className={classes.navLink} >
                    <ArrowForwardIcon className={classes.icons} />
                      Become an instructor
                  </Button>
                </Link>
              </ListItem>

              {/* <Hidden mdUp>
              <ListItem className={classes.listItem}>
                <Link href="/about-me">
                  <Button href="/about-me" color="transparent" className={classes.navLink}>
                      <InfoIcon className={classes.icons} /> About Us
                  </Button>
                  </Link>
              </ListItem>
              </Hidden> */}
            </>
          ) : (
            <>
              {!desktop && (
                <HeaderLinksMobileAdmin classes={classes} {...props} />
              )}

              {props?.user?.instructor && (
                <ListItem className={classes.listItem} style={{fontSize: "x-large"}} id="livestudio">
                  <Link href="/my/livestudio">
                    <Button color="transparent" className={classes.navLink} >
                        <MicIcon className={classes.icons} />Live Studio
                    </Button>
                  </Link>
                </ListItem>
              )}


            <ListItem className={classes.listItem} style={{fontSize: "x-large"}} id="live">
              <Link href="/my/live">
                <Button color="transparent" className={classes.navLink} >
                    <LiveTvIcon className={classes.icons} /> Live
                </Button>
              </Link>
            </ListItem>

            <ListItem className={classes.listItem} id="calendar">
              <Link href="/my/calendar">
                <Button color="transparent" className={classes.navLink} >
                    <EventNoteIcon className={classes.icons} /> Calendar
                </Button>
              </Link>
            </ListItem>

            {props?.domainInfo?.vod && (
              <ListItem className={classes.listItem} id="vod">
                <Link href="/my/vod">
                  <Button color="transparent" className={classes.navLink} >
                      <FiberDvrIcon className={classes.icons} /> VOD
                  </Button>
                </Link>
              </ListItem>
            )}

            {props?.domainInfo?.shop && (
              <>
                {(props?.domainInfo?.shoplinks && props?.domainInfo?.shoplinks?.length >= 1) ? (
                  <ListItem className={classes.listItem}>
                    <CustomDropdown
                      navDropdown
                      buttonText="Shop"
                      buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                      }}
                      customIcon={<StoreOutlinedIcon className={classes.icons} />}
                      renderMap={props?.domainInfo?.shoplinks}
                      {...props}
                    />
                  </ListItem>
                ) : (
                  <ListItem className={classes.listItem}>
                    <Link href={props?.domainInfo?.shopredir ? props?.domainInfo?.shopredir : "/shop"}>
                      <Button color="transparent" className={classes.navLink}> 
                          <StoreOutlinedIcon className={classes.icons} /> Shop
                      </Button>
                    </Link>
                  </ListItem>
                )}
              </>
            )}

            {props?.domainInfo?.blog && (
              <ListItem className={classes.listItem}>
                <Link href="/blog">
                  <Button color="transparent" className={classes.navLink}> 
                      <BookOutlinedIcon className={classes.icons} /> Blog
                  </Button>
                </Link>
              </ListItem>
            )}

            {desktop ? (
              <ListItem className={classes.listItem} id="myaccount">
                <Link href="/my/account">
                  <Button href="/my/account" color="transparent" className={classes.navLink}>
                      <AccountCircleOutlinedIcon className={classes.icons} /> My Account
                  </Button>
                  </Link>
              </ListItem>
            ) : (
              <HeaderLinksMobile classes={classes} {...props} />
            )}

            <Hidden mdUp>
              <ListItem className={classes.listItem}>
                <Link href="/about-me">
                  <Button href="/about-me" color="transparent" className={classes.navLink}>
                      <InfoIcon className={classes.icons} /> About {props?.domainInfo?.domainName == "www" ? 'Us' : 'Me'}
                  </Button>
                  </Link>
              </ListItem>
            </Hidden>

            </>
            )}
            <ListItem className={classes.listItem}>
              <Button color="transparent" className={classes.navLink} onClick={ async (e) => {
                  try {
                    e.preventDefault();
                    await mutateUser(fetchJson("/api/users?action=logout"))
                    await logout()
                    setUser({})
                    setLoggedOut(true)
                    await Router.push('/')
                  } catch (e) {
                    console.error(e)
                  }
                }}
              >
                  <ExitToAppIcon className={classes.icons} /> Logout
              </Button>
            </ListItem>
      </>
      )} 
    </List>
  );
}

export default HeaderLinks



